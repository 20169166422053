import contracts from './contracts'
import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // {
  //   sousId: 0,
  //   tokenName: 'ZOOM',
  //   stakingTokenName: QuoteToken.MIMO,
  //   stakingTokenAddress: contracts.cake[4690],
  //   contractAddress: {
  //     4690: '0x45a91032F6f385fd4c67AcD1f16A51E1d3828303',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://exchange.zoomswap.io/',
  //   harvest: true,
  //   tokenPerBlock: '100',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'TKN',
  //   stakingTokenName: QuoteToken.ZOOM,
  //   stakingTokenAddress: contracts.zoom[4689],
  //   contractAddress: {
  //     4690: '0x6e81cf8BeAB639713C973660ea36b9Ae7A9A64c8',
  //     4689: '0x7116e282E20b51E97D16F4F8330E6BF7a171bA58',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: 'https://exchange.zoomswap.io/',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  {
    sousId: 2,
    tokenName: 'GFT',
    stakingTokenName: QuoteToken.ZOOM,
    stakingTokenAddress: contracts.zoom[4689],
    contractAddress: {
      4690: '',
      4689: '0xA613810d626E7b3977d7106F2C6B5eD22DF59136',
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://starcrazy.com/',
    harvest: true,
    tokenPerBlock: '0.000675154320987654',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 3,
    tokenName: 'METX',
    stakingTokenName: QuoteToken.ZOOM,
    stakingTokenAddress: contracts.zoom[4689],
    contractAddress: {
      4690: '',
      4689: '0xf6762B4e1397997d4a7F541Cdb9184b7D10557dd',
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://www.metanyx.com/',
    harvest: true,
    tokenPerBlock: '6.028356481481482',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 4,
    tokenName: 'MCN',
    stakingTokenName: QuoteToken.ZOOM,
    stakingTokenAddress: contracts.zoom[4689],
    contractAddress: {
      4690: '',
      4689: '0x69A98bC6c5C6e1f568834Fa8F1406dcf40c3dDd8',
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://mcn.ventures/',
    harvest: true,
    tokenPerBlock: '0.000887346',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  // {
  //   sousId: 0,
  //   tokenName: 'CAKE',
  //   stakingTokenName: QuoteToken.ZOOM,
  //   stakingTokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://exchange.zoomswap.io/',
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'TWT',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
