import { MenuEntry } from '@zoomswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    translationId: 10008,
    icon: 'HomeIcon',
    activeIcon: 'HomeActiveIcon',
    href: '/',
  },
  {
    label: 'Trade',
    translationId: 10009,
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        translationId: 10013,
        href: 'https://exchange.zoomswap.io/',
      },
      {
        label: 'Liquidity',
        translationId: 10014,
        href: 'https://exchange.zoomswap.io/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    translationId: 10010,
    activeIcon: 'FarmActiveIcon',
    href: '/farms',
  },
  {
    label: 'Zoom Pools (New)',
    icon: 'PoolIcon',
    // translationId: 10010,
    activeIcon: 'PoolActiveIcon',
    href: '/pools',
  },
  {
    label: 'Rocket Pools (New)',
    icon: 'RocketIcon',
    // translationId: 10010,
    activeIcon: 'RocketActiveIcon',
    href: '/rocket-pools',
  },
  {
    label: 'ZoomSwap V2',
    // translationId: 10008,
    icon: 'HomeIcon',
    activeIcon: 'HomeActiveIcon',
    href: 'https://zoomswap.io/',
  },
  {
    label: 'Bridge',
    activeIcon: 'IotubeIcon',
    icon: 'IotubeIcon',
    href: 'https://iotube.org',
    translationId: 10016,
  },
  {
    label: 'About',
    icon: 'AboutIcon',
    translationId: 10011,
    activeIcon: 'AboutIcon',
    href: 'https://docs.zoomswap.io',
  },
  {
    label: 'Docs',
    activeIcon: 'DocsIcon',
    translationId: 10012,
    icon: 'DocsIcon',
    href: 'https://docs.zoomswap.io',
  },
  {
    label: 'Github',
    activeIcon: 'GithubIcon',
    icon: 'GithubIcon',
    href: 'https://github.com/zoomswap',
  },
  {
    label: 'Telegram',
    activeIcon: 'TelegramIcon',
    icon: 'TelegramIcon',
    href: 'https://t.me/zoomswapgroup',
  },
]

export default config
